import { serviceGet } from './_factory'

const getById = (id, token) =>
  serviceGet(
    `/v1/elearning-reports-v2/${id ? encodeURI(id) : 'latest'}`,
    undefined,
    token
  )
const getItemList = (query, token) =>
  serviceGet('/v1/elearning-reports-v2/items', query, token)
const getStats = (query, token) =>
  serviceGet('/v1/elearning-reports-v2/stats', query, token)

const elearningPackageService = {
  getById,
  getItemList,
  getStats
}

export default elearningPackageService
