import { useContext } from 'react'
//
import { FirebaseAuthContext } from '../contexts/FirebaseAuthContext'

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(FirebaseAuthContext)

  if (!context) throw new Error('Auth context must be use inside AuthProvider')

  return context
}

export default useAuth
