import { serviceDelete, serviceGet, servicePost, servicePut } from './_factory'

const getList = (query, token) => serviceGet('/v1/coupons', query, token)
const getByIdOrCode = (idOrCode, token) =>
  serviceGet(`/v1/coupons/${encodeURI(idOrCode)}`, undefined, token)
//
const create = (data, token) => servicePost('/v1/coupons', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/coupons/${encodeURI(id)}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/coupons/${encodeURI(id)}`, undefined, token)

const couponService = {
  getList,
  getByIdOrCode,
  //
  create,
  update,
  remove
}

export default couponService
