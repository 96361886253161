// react
import React, { useMemo } from 'react'
// next
import Head from 'next/head'
import Script from 'next/script'
import dynamic from 'next/dynamic'
// contexts
import { CollapseDrawerProvider } from '~/contexts/CollapseDrawerContext'
// theme
import ThemeProvider from '~/theme'
// components
import ProgressBar from '~/components/ProgressBar'
import NotistackProvider from '~/components/NotistackProvider'
import MotionLazyContainer from '~/components/animate/MotionLazyContainer'
import ImpactTestimonyPopup from '~/components/ImpactTestimonyPopup'
// mui
import { Box, CircularProgress } from '@mui/material'
// emotion
import { CacheProvider } from '@emotion/react'
import createEmotionCache from '~/libs/createEmotionCache'
// swr
import { SWRConfig } from 'swr'
// auth
import { FirebaseAuthProvider } from '~/contexts/FirebaseAuthContext'
// tracker
import { TagManagerProvider } from '~/contexts/TagManagerContext'
// luxon
import { Settings } from 'luxon'
// error handling
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
// firebase
import { initializeApp } from 'firebase/app'
// config
import { FIREBASE_API, BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE } from '~/config'

// luxon locale and timezone config
Settings.defaultLocale = 'id'

// lazy load error overlay
const ErrorOverlay = dynamic(() => import('~/components/ErrorOverlay'), {
  ssr: false,
  loading: () => (
    <Box
      sx={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: 'overlay',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.paper'
      }}
    >
      <CircularProgress />
    </Box>
  )
})

// emotion cache for SSR
const clientSideEmotionCache = createEmotionCache()

// SWR Config
const SWRConfigValue = {
  refreshInterval: 0,
  shouldRetryOnError: false,
  revalidateOnFocus: false
}

// --------------------------------------------

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const getLayout = Component.getLayout ?? ((page) => page)

  // initialize firebase
  // ----------------------------------------------------------------------
  const firebaseApp = useMemo(() => {
    return initializeApp(FIREBASE_API)
  }, [])

  const ErrorBoundary = useMemo(() => {
    // fallback to
    if (!BUGSNAG_API_KEY) {
      const ErrorBoundaryPlaceholder = ({ c }) => c

      return ErrorBoundaryPlaceholder
    }

    // bugsnag
    if (!Bugsnag._client && BUGSNAG_API_KEY) {
      Bugsnag.start({
        apiKey: BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()],
        releaseStage: BUGSNAG_RELEASE_STAGE ?? 'development',
        enabledReleaseStages: ['production', 'staging']
      })
    }

    return Bugsnag.getPlugin('react').createErrorBoundary(React)
  }, [])

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='initial-scale=1, width=device-width'
        />
      </Head>

      <CacheProvider value={emotionCache}>
        <SWRConfig value={SWRConfigValue}>
          <ThemeProvider>
            <NotistackProvider>
              <ErrorBoundary FallbackComponent={ErrorOverlay}>
                <FirebaseAuthProvider firebaseApp={firebaseApp}>
                  <TagManagerProvider firebaseApp={firebaseApp}>
                    <CollapseDrawerProvider>
                      <MotionLazyContainer>
                        <ProgressBar />
                        {getLayout(<Component {...pageProps} />)}

                        <ImpactTestimonyPopup />

                        <Script
                          strategy='lazyOnload'
                          src='https://static.cloudflareinsights.com/beacon.min.js'
                          data-cf-beacon='{"token": "771a4cfdbdd64f7ca9d3298d5472e30f"}'
                        />
                      </MotionLazyContainer>
                    </CollapseDrawerProvider>
                  </TagManagerProvider>
                </FirebaseAuthProvider>
              </ErrorBoundary>
            </NotistackProvider>
          </ThemeProvider>
        </SWRConfig>
      </CacheProvider>
    </>
  )
}

// ----------------------------------------------------------------------
