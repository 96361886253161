// ----------------------------------------------------------------------

/**
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').Components}
 */
export default function Radio(theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          svg: {
            fontSize: 24,
            '&[font-size=small]': {
              fontSize: 20
            }
          }
        }
      }
    }
  }
}
