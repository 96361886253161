// @mui
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ----------------------------------------------------------------------

/**
 * @param {string} query
 * @param {string} breakpoint - only applies to 'up', 'down', and 'only' queries
 * @param {string} breakpointStart - only applies to 'between' queries
 * @param {string} breakpointEnd - only applies to 'between' queries
 * @returns {boolean}
 */
export default function useResponsive(
  query,
  breakpoint,
  breakpointStart,
  breakpointEnd
) {
  const theme = useTheme()

  const mediaUp = useMediaQuery(theme.breakpoints.up(breakpoint))

  const mediaDown = useMediaQuery(theme.breakpoints.down(breakpoint))

  const mediaBetween = useMediaQuery(
    theme.breakpoints.between(breakpointStart, breakpointEnd)
  )

  const mediaOnly = useMediaQuery(theme.breakpoints.only(breakpoint))

  if (query === 'up') {
    return mediaUp
  }

  if (query === 'down') {
    return mediaDown
  }

  if (query === 'between') {
    return mediaBetween
  }

  if (query === 'only') {
    return mediaOnly
  }

  console.warn(
    `Invalid query value "${query}" for useResponsive, expected one of: up, down, between, only. Falling back to false.`
  )

  return false
}
