import axios from 'axios'

import { serviceGet, servicePost } from './_factory'

const createCFUploadURL = (token) =>
  serviceGet('/v1/images/cloudflare', {}, token)
const uploadImageToCF = (uploadUrl, data) =>
  axios.post(uploadUrl, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

const uploadImage = (data, token) => servicePost('/v1/images', data, token)

const imageService = {
  // cloudflare
  createCFUploadURL,
  uploadImageToCF,
  // self host
  uploadImage
}

export default imageService
