import { serviceDelete, serviceGet, servicePost, servicePut } from './_factory'

// topic
const getList = (query, token) => serviceGet('/v1/topics', query, token)
const getByIdOrSlug = (id, token) =>
  serviceGet(`/v1/topics/${encodeURI(id)}`, undefined, token)
const create = (data, token) => servicePost('/v1/topics', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/topics/${encodeURI(id)}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/topics/${encodeURI(id)}`, undefined, token)

// progress
const getProgressList = (query, token) =>
  serviceGet('/v1/topics/progresses', query, token)
const getProgressById = (id, query, token) =>
  serviceGet(`/v1/topics/progresses/${encodeURI(id)}`, query, token)

const topicService = {
  // topic
  getList,
  getByIdOrSlug,
  create,
  update,
  remove,
  // progress
  getProgressList,
  getProgressById
}

export default topicService
