import axios from 'axios'

// factory
import { getEndpointUrl, serviceGet, servicePost, servicePut } from './_factory'

// mentoring
const getList = (query, token) =>
  serviceGet('/v1/mentorings/reviews', query, token)
const getById = (id, token) =>
  serviceGet(`/v1/mentorings/reviews/${encodeURI(id)}`, undefined, token)
const getStats = (query, token) =>
  serviceGet('/v1/mentorings/reviews/stats', query, token)
const getCount = (query, token) =>
  serviceGet('/v1/mentorings/reviews/count', query, token)
const create = (data, token) =>
  servicePost('/v1/mentorings/reviews', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/mentorings/reviews/${encodeURI(id)}`, data, token)

// export
const exportToCSV = (query, token) =>
  axios.get(getEndpointUrl('/v1/mentorings/reviews/exports/csv'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    responseType: 'blob',
    params: query
  })

const mentoringService = {
  // mentoring
  getList,
  getById,
  getStats,
  getCount,
  create,
  update,
  // export
  exportToCSV
}

export default mentoringService
