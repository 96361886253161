import { serviceDelete, serviceGet, servicePost } from './_factory'

const getList = (query, token) => serviceGet('/v1/certificates', query, token)
const create = (data, token) => servicePost('/v1/certificates', data, token)
const deleteCertificate = (id, token) =>
  serviceDelete(`/v1/certificates/${encodeURI(id)}`, undefined, token)

const certificateService = {
  getList,
  create,
  deleteCertificate
}

export default certificateService
