// @mui
import { Box } from '@mui/material'
// config
import { IMAGE_CDN_URL } from '~/config'

// ----------------------------------------------------------------------

const PNG_EXT_REGEX = /\.png$/i

const BASE_SX = {
  fontSize: 'small',
  color: 'text.secondary',
  backgroundImage:
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOcOG+1LQAFuwIZ+/8pcwAAAABJRU5ErkJggg==)'
}

// ----------------------------------------------------------------------

/**
 * @typedef {Object} NativeImageBaseProps
 * @property {string} [src]
 * @property {string} [alt]
 * @property {import('react').ComponentProps<'img'>['loading']} [loading]
 *
 * @typedef {Omit<import('@mui/material').BoxProps, 'children' | 'component' | 'as'>} NativeImageMUIProps
 *
 * @typedef {NativeImageBaseProps & NativeImageMUIProps} NativeImageProps
 */

/**
 * @param {NativeImageProps} props
 * @returns {JSX.Element}
 */
export default function NativeImage({ src, alt, loading = 'lazy', ...rest }) {
  const isCDNImage =
    src &&
    !(
      src.startsWith('http://') ||
      src.startsWith('https://') ||
      src.startsWith('data:')
    )

  const fallbackSRC = isCDNImage ? `${IMAGE_CDN_URL}/${src}` : src

  return (
    <Box
      {...rest}
      component='picture'
      sx={{ ...BASE_SX, ...rest?.sx }}
    >
      {isCDNImage && (
        <>
          <source
            srcSet={`${IMAGE_CDN_URL}/${src.replace(PNG_EXT_REGEX, '.webp')}`}
            type='image/webp'
          />
          <source
            srcSet={`${IMAGE_CDN_URL}/${src.replace(PNG_EXT_REGEX, '.webp')}`}
            type='image/avif'
          />
          <source
            srcSet={`${IMAGE_CDN_URL}/${src}`}
            type='image/png'
          />
        </>
      )}

      <Box
        {...rest}
        component='img'
        src={fallbackSRC}
        alt={alt}
        loading={loading}
        sx={{ ...BASE_SX, ...rest?.sx }}
      />
    </Box>
  )
}
