import { serviceGet, servicePost } from './_factory'

// feedback
const getList = (query, token) => serviceGet('/v1/feedbacks', query, token)
const getById = (id, token) =>
  serviceGet(`/v1/feedbacks/${encodeURI(id)}`, {}, token)
const getStats = (query, token) =>
  serviceGet('/v1/feedbacks/stats', query, token)
const create = (data, token) => servicePost('/v1/feedbacks', data, token)

// feedback aggregate
const getAggregateList = (query, token) =>
  serviceGet('/v1/feedbacks/aggregates', query, token)
const getAggregate = (productType, productId, query, token) =>
  serviceGet(
    `/v1/feedbacks/aggregates/${encodeURI(productType)}/${encodeURI(productId)}`,
    query,
    token
  )

const feedbackService = {
  // feedback
  getList,
  getById,
  getStats,
  create,
  // feedback aggregate
  getAggregateList,
  getAggregate
}

export default feedbackService
