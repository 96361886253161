import { Public_Sans as PublicSans } from 'next/font/google'

import { pxToRem, responsiveFontSizes } from '~/libs/getFontValue'

// ----------------------------------------------------------------------

const publicSans = PublicSans({
  weight: ['variable'],
  subsets: ['latin'],
  display: 'swap'
})

// --------------------------------------------

const FONT_PRIMARY = publicSans.style.fontFamily

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: pxToRem(40),
    letterSpacing: 2,
    ...responsiveFontSizes({ sm: 48, md: 56 })
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 36, md: 40 })
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.25,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 28, md: 32 })
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.25,
    fontSize: pxToRem(20)
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18)
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18)
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
    textTransform: 'capitalize'
  }
}

export default typography
