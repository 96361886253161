import { serviceGet } from './_factory'

// elearning subscription
const getElearning = (userId, token) =>
  serviceGet(`/v1/subscriptions/e-learning/${encodeURI(userId)}`, null, token)
const getElearningUserList = (query, token) =>
  serviceGet('/v1/subscriptions/e-learning', query, token)
const getElearningUserCount = (query, token) =>
  serviceGet('/v1/subscriptions/e-learning/count', query, token)
const exportElearningToCSV = (query, token) =>
  serviceGet('/v1/subscriptions/e-learning/exports/csv', query, token)

// review subscription
const getReview = (userId, token) =>
  serviceGet(`/v1/subscriptions/review/${encodeURI(userId)}`, null, token)
const getReviewUserList = (query, token) =>
  serviceGet('/v1/subscriptions/review', query, token)
const getReviewUserCount = (query, token) =>
  serviceGet('/v1/subscriptions/review/count', query, token)
const exportReviewToCSV = (query, token) =>
  serviceGet('/v1/subscriptions/review/exports/csv', query, token)

const subscriptionService = {
  // elearning subscription
  getElearning,
  getElearningUserList,
  getElearningUserCount,
  exportElearningToCSV,
  // review subscription
  getReview,
  getReviewUserList,
  getReviewUserCount,
  exportReviewToCSV
}

export default subscriptionService
