import { serviceDelete, serviceGet, servicePost } from './_factory'

const getList = (query, token) => serviceGet('/v1/vouchers', query, token)
const getById = (id, token) =>
  serviceGet(`/v1/vouchers/${encodeURI(id)}`, undefined, token)
const getByCode = (code, token) => serviceGet('/v1/vouchers', { code }, token)
const create = (data, token) => servicePost('/v1/vouchers', data, token)
const submitRedeem = (data, token) =>
  servicePost('/v1/vouchers/redeems', data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/vouchers/${encodeURI(id)}`, undefined, token)

const voucherService = {
  getList,
  getById,
  getByCode,
  create,
  submitRedeem,
  remove
}

export default voucherService
