import { serviceGet, servicePost } from './_factory'

const getList = (query, token) =>
  serviceGet('/v1/product-activities', query, token)
const getCount = (query, token) =>
  serviceGet('/v1/product-activities/count', query, token)
const getStats = (query, token) =>
  serviceGet('/v1/product-activities/stats', query, token)
export const create = (data, token) =>
  servicePost('/v1/product-activities', data, token)

const productActivityService = {
  getList,
  getCount,
  getStats,
  create
}

export default productActivityService
