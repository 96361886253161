import axios from 'axios'
import { getEndpointUrl, serviceGet, servicePost, servicePut } from './_factory'

// invoice
const getList = (query, token) => serviceGet('/v1/invoices', query, token)
const getByIdOrOrderId = (idOrOrderId, token) =>
  serviceGet(`/v1/invoices/${encodeURI(idOrOrderId)}`, undefined, token)
const getStats = (query, token) =>
  serviceGet('/v1/invoices/stats', query, token)
const create = (data, token) => servicePost('/v1/invoices', data, token)
const createCustom = (data, token) =>
  servicePost('/v1/invoices/custom', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/invoices/${encodeURI(id)}`, data, token)

// payment method
const getPaymentMethodList = (query, token) =>
  serviceGet('/v1/invoices/payment-methods', query, token)

// export
const exportToCSV = (params, token) =>
  serviceGet('/v1/invoices/exports/csv', params, token)
const exportToPDF = (invoiceId, token) =>
  axios.get(getEndpointUrl(`/v1/invoices/${encodeURI(invoiceId)}/pdf`), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    responseType: 'blob'
  })

const invoiceService = {
  // invoice
  getList,
  getByIdOrOrderId,
  getStats,
  create,
  createCustom,
  update,
  // payment method
  getPaymentMethodList,
  // export
  exportToCSV,
  exportToPDF
}

export default invoiceService
