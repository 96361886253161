import { servicePost } from './_factory'

const sendMessage = (data, token) =>
  servicePost('/v1/experiences/messages', data, token)

const experienceService = {
  sendMessage
}

export default experienceService
