// react
import { useEffect, useMemo, useState } from 'react'
// next
import { useRouter } from 'next/router'
// @mui
import { Box, Slide, Snackbar } from '@mui/material'
// components
import ImpactTestimonyCard from './ImpactTestimonyCard'
// hooks
import useAuth from '~/hooks/useAuth'
import useResponsive from '~/hooks/useResponsive'
// constants
import { IMPACT_TESTIMONY } from '~/constants/testimony/impact'
// utils
import { shuffle } from 'lodash'

// ----------------------------------------------------------------------

const TransitionLeft = (props) => {
  return (
    <Slide
      {...props}
      direction='right'
    />
  )
}

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function ImpactTestimonyPopup() {
  const { isReady, pathname } = useRouter()
  const { isAuthenticated, isInitialized } = useAuth()
  const mdUp = useResponsive('up', 'md')

  // shuffle impact testimony order
  const randomizedImpactTestimony = useMemo(() => shuffle(IMPACT_TESTIMONY), [])

  // enable or disable popup
  const isEnabled = useMemo(() => {
    return (
      isInitialized &&
      isReady &&
      // dont show popup if user is authenticated
      !isAuthenticated &&
      // only show popup on these pages
      (pathname === '/' ||
        pathname.startsWith('/e-learning') ||
        pathname.startsWith('/learning-path') ||
        pathname.startsWith('/topic') ||
        pathname.startsWith('/bootcamp') ||
        pathname.startsWith('/mentoring'))
    )
  }, [isAuthenticated, isInitialized, isReady, pathname])

  // popup state
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0)

  // open popup every 50 seconds
  useEffect(() => {
    if (!isEnabled || !mdUp) return

    let interval = null

    setTimeout(() => {
      // show first popup after 6s delay
      setIsPopupOpen(true)

      // after that show popup every 30s
      interval = setInterval(() => {
        setIsPopupOpen(true)
      }, 30000)
    }, 6000)

    return () => {
      clearInterval(interval)
    }
  }, [isEnabled, mdUp])

  // handle popup close
  const handleClose = () => {
    setIsPopupOpen(false)

    // increment popup index 1s after close
    setTimeout(() => {
      setCurrentPopupIndex((prev) => {
        const next = prev + 1

        // reset popup index if it exceeds the length of impact testimony
        if (next >= randomizedImpactTestimony.length) {
          return 0
        }

        return next
      })
    }, 1000)
  }

  if (!isEnabled) return null

  return (
    <Snackbar
      key={`impact-testimony-popup-${currentPopupIndex}`}
      open={isPopupOpen}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      // auto hide after 6s
      autoHideDuration={6000}
    >
      <Box maxWidth='40ch'>
        <ImpactTestimonyCard
          {...randomizedImpactTestimony[currentPopupIndex]}
          onClose={handleClose}
        />
      </Box>
    </Snackbar>
  )
}
