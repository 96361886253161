// ----------------------------------------------------------------------

/**
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').Components}
 */
export default function Typography(theme) {
  return {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'overline' },
          style: {
            color: theme.palette.text.secondary
          }
        },
        {
          props: { variant: 'basic-heading-primary' },
          style: {
            fontSize: 'large',
            fontWeight: 'bold',
            color: theme.palette.primary.main
          }
        },
        {
          props: { variant: 'form-section-heading' },
          style: {
            display: 'block',
            ...theme.typography.subtitle2,
            color: theme.palette.black,
            marginBottom: theme.spacing(1)
          }
        },
        {
          props: { variant: 'form-field-label' },
          style: {
            ...theme.typography.subtitle2,
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1)
          }
        },
        {
          props: { variant: 'hx' },
          style: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
              fontSize: '1.5rem'
            }
          }
        },
        {
          props: { variant: 'hx-brand' },
          style: {
            color: theme.palette.primary.main,
            fontSize: '1.25rem',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
              fontSize: '1.5rem'
            }
          }
        },
        {
          props: { variant: 'caption' },
          style: {
            fontSize: 'small',
            fontWeight: theme.palette.text.secondary
          }
        },
        {
          props: { variant: 'value-label' },
          style: {
            fontSize: 'small',
            fontWeight: 'bold'
          }
        }
      ],

      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        }
      }
    }
  }
}
