// animation
import { m } from 'framer-motion'
// @mui
import { Box } from '@mui/material'
//
import { varContainer } from './variants'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {boolean} props.animate
 * @param {boolean} props.action
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function MotionContainer({
  animate,
  action = false,
  children,
  ...other
}) {
  if (action) {
    return (
      <Box
        component={m.div}
        initial={false}
        animate={animate ? 'animate' : 'exit'}
        variants={varContainer()}
        {...other}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      component={m.div}
      initial='initial'
      animate='animate'
      exit='exit'
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  )
}
