// animation
import { m } from 'framer-motion'
// @mui
import { Box } from '@mui/material'
// hooks
import useResponsive from '../../hooks/useResponsive'
//
import { varContainer } from '.'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} props.disableAnimatedMobile
 * @returns {JSX.Element}
 */
export default function MotionViewport({
  children,
  disableAnimatedMobile = false,
  ...other
}) {
  const isMobile = useResponsive('down', 'sm')

  if (isMobile && disableAnimatedMobile) {
    return <Box {...other}>{children}</Box>
  }

  return (
    <Box
      component={m.div}
      initial='initial'
      whileInView='animate'
      viewport={{ once: true, amount: 0.3 }}
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  )
}
