import axios from 'axios'

export const getEndpointUrl = (path) =>
  `${process.env.NEXT_PUBLIC_API_BASE_URL}${path}`

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      error.message =
        'Unable to connect to server, please try again in a while.'
    }

    if (!error.response) {
      error.message =
        'Unable to get desired response from server, please try again in a while.'
    }

    return Promise.reject(error)
  }
)

// POST
export const servicePost = (endpoint, data, token) =>
  axios.post(getEndpointUrl(endpoint), data, {
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  })

// GET
export const serviceGet = (endpoint, params, token) =>
  axios.get(getEndpointUrl(endpoint), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params
  })

// PUT
export const servicePut = (endpoint, data, token) =>
  axios.put(getEndpointUrl(endpoint), data, {
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  })

// PATCH
export const servicePatch = (endpoint, data, token) =>
  axios.patch(getEndpointUrl(endpoint), data, {
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  })

// DELETE
export const serviceDelete = (endpoint, params, token) =>
  axios.delete(getEndpointUrl(endpoint), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params
  })
