// please note that impact testimony is exposed via Next.js API for Dana Miniprogram
// so if you need to add / update image here, upload the image to MySkill Image CDN
// instead of using image static import here

/**
 * @typedef ImpactTestimony
 * @property {{
 *   small: string
 *   full: string
 * }} avatarURL
 * @property {string} name
 * @property {string} position
 * @property {string} company
 * @property {string} testimony
 */

/**
 * @type {ImpactTestimony[]}
 */
export const IMPACT_TESTIMONY = Object.freeze([
  {
    name: 'Paksi Cahyo Baskoro',
    position: 'Copywriter',
    company: 'DBS Bank Indonesia',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/paksi.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/paksi.jpg'
    }
  },
  {
    name: 'M. Arkhan Doohan',
    position: 'Data Analyst',
    company: 'United Tractors',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/arkhan.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/arkhan.jpg'
    }
  },
  {
    name: 'Dave Emmanuel',
    position: 'Business Development Analyst',
    company: 'Shopee',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/dave.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/dave.jpg'
    }
  },
  {
    name: 'Latifah NH Putri',
    position: 'HR Generalist',
    company: 'TIX ID',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/latifah.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/latifah.jpg'
    }
  },
  {
    name: 'Evira Aurellia',
    position: 'Staff Finance',
    company: 'Basic Pratama Production',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/evira.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/evira.jpg'
    }
  },
  {
    name: 'Dzul Fiqram Nur',
    position: 'Content Specialist',
    company: 'Amar Bank',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/dzul.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/dzul.jpg'
    }
  },
  {
    name: 'M. Rajib Abdul G.',
    position: 'Business Development Executive',
    company: 'PT. Dachin Etech Global',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/rajib.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/rajib.jpg'
    }
  },
  {
    name: 'Yusril Azmi',
    position: 'Social Media Specialist',
    company: 'Digitaluna.id',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/yusril.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/yusril.jpg'
    }
  },
  {
    name: 'Hamdiki Ainur',
    position: 'Associate Acquisition',
    company: 'ADA',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/hamdiki.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/hamdiki.jpg'
    }
  },
  {
    name: 'Brian Marco',
    position: 'Communications Designer',
    company: 'C+C&Co',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/brian.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/brian.jpg'
    }
  },
  {
    name: 'Dedy Setiyawan',
    position: 'Digital Marketing',
    company: 'ASTRNT Technologies',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/dedy.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/dedy.jpg'
    }
  },
  {
    name: 'Arya Dharma',
    position: 'Digital Marketing Specialist',
    company: 'Imuni.id',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/arya.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/arya.jpg'
    }
  },
  {
    name: 'M. Fauzan Labib',
    position: 'Performance Marketing',
    company: 'Home Appliances Company',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/fauzan.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/fauzan.jpg'
    }
  },
  {
    name: 'Muhammad Rizqullah',
    position: 'Digital Marketing Intern',
    company: 'Erajaya Swasembada',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/rizqullah.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/rizqullah.jpg'
    }
  },
  {
    name: 'Muhammad Rizki M.',
    position: 'Technical Intern',
    company: 'PT. Nestle Indonesia',
    avatar: {
      small: 'https://image.myskill.id/impact-testimony/80w/rizki.jpg',
      normal: 'https://image.myskill.id/impact-testimony/240w/rizki.jpg'
    }
  }
])
