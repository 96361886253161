import axios from 'axios'
import {
  getEndpointUrl,
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost,
  servicePut
} from './_factory'

// ------------------------------------

const getList = (query, token) => serviceGet('/v1/users', query, token)
const getById = (id, token) =>
  serviceGet(`/v1/users/${encodeURI(id)}`, undefined, token)
const create = (data, token) => servicePost('/v1/users', data, token)
const update = (id, data, token) =>
  servicePatch(`/v1/users/${encodeURI(id)}`, data, token)
const updateEmail = (id, data, token) =>
  servicePut(`/v1/users/${encodeURI(id)}/email`, data, token)
const updateCampaignConsent = (id, data, token) =>
  servicePut(`/v1/users/${encodeURI(id)}/campaign-consent`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/users/${encodeURI(id)}`, undefined, token)

// export
const exportToCSV = (query, token) =>
  axios.get(getEndpointUrl('/v1/users/exports/csv'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    responseType: 'blob',
    params: query
  })

// actions
const sendResetPasswordEmail = (id, token) =>
  servicePost(`/v1/users/${encodeURI(id)}/reset-password`, undefined, token)

// non standard service function, for shorthand

/**
 * Note: will not throw error if user not found or fetch failed
 * @returns {Promise<Object|null>} user or null
 */
const getByEmail = async (email, token) => {
  const res = await serviceGet('/v1/users', { email, limit: 1 }, token)

  const user = res.data.results[0] ?? null

  return user
}

const getCount = (query, token) => serviceGet('/v1/users/count', query, token)

/**
 * Note: will not throw error if user not found or fetch failed
 * @returns {Promise<Object|null>} user or null
 */
const getByEmailOrId = async (emailOrId, token) => {
  if (!emailOrId) return null

  const [resEmail, resId] = await Promise.allSettled([
    getByEmail(emailOrId.toLowerCase(), token),
    getById(emailOrId, token)
  ])

  const fetchedUser = resEmail.value ?? resId.value?.data?.results ?? null

  return fetchedUser ?? null
}

const userService = {
  getList,
  getById,
  getCount,
  create,
  update,
  updateEmail,
  updateCampaignConsent,
  remove,
  // export
  exportToCSV,
  // action
  sendResetPasswordEmail,
  //
  getByEmail,
  getByEmailOrId
}

export default userService
