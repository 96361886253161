import { serviceDelete, serviceGet, servicePost, servicePut } from './_factory'

// elearning package
const getList = (query, token) =>
  serviceGet('/v1/elearning-packages', query, token)
const getById = (id, token) =>
  serviceGet(`/v1/elearning-packages/${encodeURI(id)}`, undefined, token)
const create = (data, token) =>
  servicePost('/v1/elearning-packages', data, token)
const update = (id, data, token) =>
  servicePut(`/v1/elearning-packages/${encodeURI(id)}`, data, token)
const remove = (id, token) =>
  serviceDelete(`/v1/elearning-packages/${encodeURI(id)}`, undefined, token)

const elearningPackageService = {
  getList,
  getById,
  create,
  update,
  remove
}

export default elearningPackageService
