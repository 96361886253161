// react
import { forwardRef } from 'react'
// animation
import { m } from 'framer-motion'
// @mui
import { Box, IconButton } from '@mui/material'

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.size
 * @returns {JSX.Element}
 */
const IconButtonAnimate = forwardRef(
  ({ children, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton
        size={size}
        ref={ref}
        {...other}
      >
        {children}
      </IconButton>
    </AnimateWrap>
  )
)

IconButtonAnimate.displayName = 'IconButtonAnimate'

export default IconButtonAnimate

// ----------------------------------------------------------------------

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 }
}

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 }
}

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 }
}

// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {string} props.size
 * @param {React.ReactNode} props.children
 * @param {Object} props.children
 * @returns {JSX.Element}
 */
function AnimateWrap({ size, children }) {
  const isSmall = size === 'small'
  const isLarge = size === 'large'

  return (
    <Box
      component={m.div}
      whileTap='tap'
      whileHover='hover'
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex'
      }}
    >
      {children}
    </Box>
  )
}
