// ----------------------------------------------------------------------

/**
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').Components}
 */
export default function Stepper(theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider
        }
      }
    }
  }
}
