import { servicePost } from './_factory'

const sendInquiryMessage = (data, token) =>
  servicePost('/v1/partnerships/inquiry-messages', data, token)

const sendExperienceMessage = (data, token) =>
  servicePost('/v1/partnerships/experience-messages', data, token)

const partnershipService = {
  sendInquiryMessage,
  sendExperienceMessage
}

export default partnershipService
