import { servicePost } from './_factory'

const create = (data, token) => servicePost('/v1/product-accesses', data, token)
const createBulk = (data, token) =>
  servicePost('/v1/product-accesses/bulk', data, token)

const productAccessService = {
  create,
  createBulk
}

export default productAccessService
