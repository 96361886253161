// react
import { createContext } from 'react'
// next
import Script from 'next/script'
// config
import { FIREBASE_API } from '~/config'

// ----------------------------------------------------------------------

const initialState = {
  sendEvent: () => null
}

const TagManagerContext = createContext({
  ...initialState
})

// ----------------------------------------------------------------------

function TagManagerProvider({ children }) {
  const sendEvent = ({ event, ...rest }) => {
    if (typeof window === 'undefined') return

    window.gtag('event', event, rest)
  }

  return (
    <TagManagerContext.Provider value={{ sendEvent }}>
      {children}

      <Script
        id='gtag-preload'
        strategy='beforeInteractive'
      >
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${FIREBASE_API.measurementId}', {
          server_container_url: 'https://gtm.myskill.id',
        });
        `}
      </Script>

      <Script
        id='gtag'
        strategy='lazyOnload'
        src={`https://gtm.myskill.id/gtag/js?id=${FIREBASE_API.measurementId}`}
      />
    </TagManagerContext.Provider>
  )
}

export { TagManagerContext, TagManagerProvider }
